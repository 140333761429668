import React from "react"
// import loadable from '@loadable/component'
import { ResponsiveRadar } from '@nivo/radar'
// const ResponsiveRadar = loadable(() => import('@nivo/radar'), {
//     resolveComponent: (components) => components.ResponsiveRadar,
// })

const Radar = ({radarData, names, size, className}) => {

    const LabelComponent = ({ id, anchor }) => (
        <g transform={`translate(${anchor === 'end' ? -50 : anchor === 'middle' ? -30 : 0}, -10)`}>
            <text y={20}
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    fill: '#3a9896',
                }}
            >{id}</text>
        </g>
    )

    return(
        <div className={"radar-chart "+className} >
            <ResponsiveRadar
                data={radarData}
                keys={names}
                indexBy="category"
                maxValue={10}
                margin={{ top: 40, right: 60, bottom: 50, left: size==="small" ? 60: 30 }}
                curve="linearClosed"
                gridLevels={6}
                gridShape="circular"
                gridLabelOffset={25}
                enableDots={true}
                enableDotLabel={size==="small"}
                dotLabelYOffset={3}
                gridLabel={LabelComponent}
                dotLabel="value"
                colors={{ scheme: size==="small"? 'paired':'set2' }}
                fillOpacity={0.1}
                blendMode="multiply"
                dotSize={size==="small" ? 28: 10}
                // animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        translateX: size === "small" ? -70: -50,
                        translateY: -40,
                        itemWidth: 80,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        symbolSize: 16,
                        // symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    )
}

export default Radar